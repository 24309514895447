<template>
    <v-dialog v-model="opened" persistent max-width="490">
        <v-card>
            <v-card-title v-text="'Want to save 40%?'" class="text-h5 justify-center"/>
            <v-card-text class="text-center pb-2">
                <p class="mt-2">You can save 40% with a one off annual payment</p>
                <p>Pay ${{ plan.price * 12 }} for 1 year's access <strong>(${{ plan.price }} per month)</strong></p>
            </v-card-text>
            <v-card-actions class="justify-center pb-3 pt-0">
                <v-btn @click="close" v-text="'No thanks'"/>
                <v-btn @click="agree" color="primary" v-text="'Yes please'"/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'OfferedDiscountDialog',
    props: {
        plan: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            opened: true
        }
    },
    methods: {
        close() {
            this.$emit('close', this.plan)
        },

        agree() {
            this.$emit('agree', this.plan)
        }
    }
}
</script>
