<template>
    <v-card class="PricePlan">
        <v-card-text class=" text-style">
            <p class="PricePlan_title card-title mt-10 mb-10" v-text="plan.name"/>
            <p>
                <span v-text="'$'" class="card-symbol"/>
                <span v-text="parsePrice(plan.price).dollars" class="card-price"/>.
                <span v-text="parsePrice(plan.price).cents"/>
            </p>
            <p class="card-fs-23" v-text="'/month'"/>
            <p v-if="period === 'Yearly'" v-text="'billed yearly'" class="card-fs-23"/>
            <p v-else v-text="'payable annually'" class="card-fs-23"/>
        </v-card-text>

        <v-card-actions>
            <v-btn
                outlined
                x-large
                color="#3bb5e9"
                @click.prevent="choosePlan(plan)"
                class="mx-auto"
                v-text="'Continue'"/>
        </v-card-actions>

        <v-card-text class="mb-5 text-style plan_description">
            <ul>
                <li v-if="plan.restrictions.maxFileSize">
                    <strong>{{plan.restrictions.maxFileSize / 1000 }} GB</strong> maximum file size
                </li>
                <li v-else><strong>Unlimited</strong> file size</li>
                <li v-if="plan.restrictions.concurrentConversions">
                    <strong>{{ plan.restrictions.concurrentConversions }}</strong> Concurrent conversions
                </li>
                <li v-else>
                    <strong>Unlimited</strong> concurrent conversions
                </li>
                <li><strong>{{priorities.find(p => p.value === plan.restrictions.priority).text }}</strong> priority</li>
            </ul>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        plan: {
            type: Object,
            required: true
        },
        period: {
            required: true
        }
    },
    data() {
        return {
            priorities: [
                {value: 1, text: 'Medium'},
                {value: 2, text: 'High'},
                {value: 3, text: 'Higher'},
                {value: 4, text: 'Highest'},
            ],
        }
    },
    methods: {
        parsePrice(price) {
            return {
                dollars: price.toString().split('.')[0],
                cents: price.toString().split('.')[1],
            }
        },
        choosePlan(plan) {
            this.$emit('choose', plan)
        }
    }
}
</script>

<style lang="scss" scoped>
    .PricePlan {
        &_title {
            font-weight: 100;
            font-size: 1.5rem;
        }

        .card-title {
            color: rgba(28, 42, 92, 0.44) !important;
        }

        .card-symbol {
            font-size: 44px;
            line-height: 40px;
        }

        .card-price {
            font-size: 99px;
            line-height: 80px
        }

        .card-fs-23 {
            font-size: 1rem;
        }

        .plan_description {
            font-size: 1rem;
        }
    }
</style>
