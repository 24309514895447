<template>
    <front-layout>
        <div class="flex-wrapper-full-background d-flex flex-wrap fill-height">
            <div class="intro mx-auto">
                <h1 class="text-style pricing-title mb-11" v-text="'Choose the right plan'"/>

                <div class="text-center">
                    <v-btn-toggle v-model="changedPeriod" class="mb-4">
                        <v-btn v-text="'Monthly'"/>
                        <v-btn v-text="'Yearly'"/>
                    </v-btn-toggle>

                    <p v-text="'Save up to 40% with annual plans'"/>
                </div>

                <v-row dense>
                    <v-col v-for="plan in pricePlans[Object.keys(pricePlans)[changedPeriod]]" :key="plan.name"
                           :cols="plan.flex" class="price-col">
                        <CardPricePlan
                            :plan="plan"
                            :period="Object.keys(pricePlans)[changedPeriod]"
                            @choose="choose"
                            class="text-center mx-3"/>
                    </v-col>
                </v-row>

                <v-container class="mt-5">
                    <h2 class="text-center">Frequently Asked Questions</h2>
                    <ContentGroup :scopes="['pricing_faq']" :cols="12">
                        <template v-slot:item="{ item }">
                            <article>
                                <h3 v-text="item.title"/>
                                <p v-html="item.text"/>
                            </article>
                        </template>
                    </ContentGroup>
                </v-container>
            </div>
        </div>

        <OfferedDiscountDialog
            v-if="hasBeenOfferedDiscountPlan"
            :plan="hasBeenOfferedDiscountPlan"
            @close="closeOfferDiscount"
            @agree="agreeOfferDiscount"/>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import PricePlan from "@/models/PricePlan";
import CardPricePlan from "../../components/cards/CardPricePlan";
import OfferedDiscountDialog from "../../components/dialogs/OfferedDiscountDialog";
import ContentGroup from "@/components/content/ContentGroup";

export default {
    name: 'pricing',
    components: {
        OfferedDiscountDialog,
        CardPricePlan,
        FrontLayout,
        ContentGroup
    },
    data() {
        return {
            changedPeriod: 0,
            changedPlan: null,
            pricePlans: {
                Monthly: [],
                Yearly: []
            },
            hasBeenOfferedDiscountPlan: null,
        }
    },

    async mounted() {
        await this.getPricePlan()
    },

    methods: {
        async getPricePlan() {
            const plans = await PricePlan
                .where('scopes', 'public')
                .where('is_active', true)
                .get();

            plans.forEach(plan => {
                this.pricePlans[plan.interval === 'month' ? 'Monthly' : 'Yearly'].push({
                    ...plan, ...{
                        price: plan.interval === 'month'
                            ? plan.amount.toFixed(2)
                            : (plan.amount / 12).toFixed(2),
                    }
                })
            })
        },

        choose(plan) {
            if (plan.interval === 'month') {
                return this.hasBeenOfferedDiscountPlan = this.pricePlans.Yearly.find(p => p.name === plan.name);
            }

            return this.$router.push({name: 'sign-up', params: {pricePlan: plan.stripe_id}});
        },

        closeOfferDiscount(plan) {
            this.hasBeenOfferedDiscountPlan = null;

            return this.$router.push({name: 'sign-up', params: { pricePlan: plan.stripe_id } });
        },

        agreeOfferDiscount(plan) {
            return this.$router.push({name: 'sign-up', params: {pricePlan: plan.stripe_id}});
        },
    },

    watch: {
        changedPeriod(val, oldVal) {
            if (!val && val != 0) {
                this.changedPeriod = oldVal;
            }
        }
    }
}
</script>

<style>
.v-input.v-input--switch--inset .v-input--switch__track:after {
    content: "No";
    color: #333333;
    font-size: 10px;
}

.v-input.v-input--switch--inset.v-input--is-label-active.v-input--is-dirty .v-input--switch__track:after {
    content: "Yes";
    color: #28A745;
}
</style>
<style scoped>


.price-carousel {
    width: 1300px;
}

.price-carousel-item {
    text-align: center;
    font-size: 30px;
}

.price-col {
    padding: 0;
    min-width: 400px;
}


.card-fs-30 {
    font-size: 30px;
}

.expand-card {
    height: 100%;
    text-align: left;
}

.pricing-title {
    font-size: 45px;
    line-height: 40px;
    text-align: center;
    color: orangered !important;

    margin-bottom: 20px;
}

.theme--dark.v-sheet {
    background-color: white !important;
}

</style>
